//
// text.scss
// 

// Weight

.fw-semibold  { font-weight: $font-weight-semibold !important; }

.text-primary { color: $primary !important; }

.text-success-light { color: $success !important; }