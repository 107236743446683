


.container {
    @include media-breakpoint-down(sm) {
        padding-right: 0px; // Kleineres Padding für mobile Ansicht
        padding-left: 0px;
      }
}
.container-fluid {
    @include media-breakpoint-down(sm) {
        padding-right: 0px; // Kleineres Padding für mobile Ansicht
        padding-left: 0px;
      }
}